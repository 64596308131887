<template>
  <div>
    <div v-if="photos.length > 0">
      <b-row class="no-gutters gallery-container">
        <div
          v-for="(photo, index) in photos"
          :key="index"
          class="mr-1 mb-1 clickable"
          @click="openGallery(index)"
        >
          <b-img :src="photo.src" class="gallery-item" />
        </div>
      </b-row>

      <light-box ref="lightbox" :media="photos" :show-light-box="false" />
    </div>
  </div>
</template>

<script>
import LightBox from "vue-image-lightbox";
export default {
  components: {
    LightBox
  },
  props: {
    photos: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    }
  }
};
</script>

<style lang="scss" scoped>
.gallery-container {
  max-height: 160px;
  overflow-x: visible;
  overflow-y: auto;
}
.gallery-item {
  border-radius: 4px;
  width: 80px;
  min-height: 60px;
  height: 100%;
}

@media screen and (max-width: 959px) {
  .gallery-container {
    max-width: unset;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>
