<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      :sort-by.sync="_sortBy"
      :sort-desc.sync="_sortDesc"
      :responsive="true"
      outlined
      hover
      show-empty
      small
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === _sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(author)="data">
        <b-avatar class="avatar mr-3" :src="data.item.avatar" />
        {{ data.value }}
      </template>
      <template #cell(createdAt)="data">
        <span class="text-capitalize">
          {{ data.value | date("DD MMMM YYYY, HH:mm") }}
        </span>
      </template>
      <template #cell(comment)="data">
        <read-more :text="data.value" />
      </template>
      <template #cell(reported)="data">
        <div v-if="data.item.reported">
          <b-icon variant="danger" icon="exclamation-triangle"></b-icon>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-row class="no-gutters justify-content-end flex-nowrap">
          <b-col
            v-for="(action, index) in actions"
            :key="index"
            class="col-auto"
          >
            <b-button
              size="sm"
              class="ml-1 text-white"
              :variant="action.variant || 'secondary'"
              @click="action.callbackFunc(data.item.id)"
            >
              {{ $t(action.label) }}
            </b-button>
          </b-col>
          <b-button
            v-if="data.item.reported"
            :id="'popover-target-' + data.item.id"
            class="text-white ml-1"
            size="sm"
          >
            <b-icon icon="three-dots"></b-icon>
          </b-button>
          <b-popover
            v-if="data.item.reported"
            :target="'popover-target-' + data.item.id"
            triggers="focus"
            placement="left"
          >
            <div style="cursor: pointer" @click="undoReport(data)">
              <b>{{ $t("button.undoReport") }}</b>
            </div>
          </b-popover>
        </b-row>
      </template>
    </b-table>
  </div>
</template>

<script>
import ReadMore from "./../../../../console-components/BaseReadMore";
export default {
  components: {
    ReadMore
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = false;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    },
    undoReport(data) {
      this.$emit("undoReport", data.item);
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-icon {
  width: 20px;
}
.sort-icon {
  width: 20px;
}
.avatar {
  --size: 30px;
  width: var(--size);
  height: var(--size);
}
</style>
